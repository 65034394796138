import './App.css';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

import logo from './assets/logo.png';
import email from './assets/mail.svg';

import ImageLoader from './components/ImageLoader';

const firebaseConfig = {
  apiKey: "AIzaSyCwhgJfOg1I9PSCXvM3yoUwEwjZEgp-QJg",
  authDomain: "bartjes-517e8.firebaseapp.com",
  projectId: "bartjes-517e8",
  storageBucket: "bartjes-517e8.appspot.com",
  messagingSenderId: "683483150424",
  appId: "1:683483150424:web:11289cf6b4250ff051b22d",
  measurementId: "G-80NQ1MWK0Y"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

logEvent(analytics, 'page_view');

function App() {
  return (
    <main className="app">
      <img src={logo} className="logo" alt="Bartjes logo" />

      <ImageLoader />

      <a href="mailto:info@bartjes.nl" className='link'>
        <img src={email} className='email-icon' alt='email icon'/> 
        info@bartjes.eu
      </a>
    </main>
  );
}

export default App;
