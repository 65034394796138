import { useState } from 'react';
import truck from "../assets/truck.jpg";
import truckSmall from "../assets/truck-small.jpeg";

function ImageLoader() {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <>
      {imageLoaded ? (
        <img src={truck} alt="" className='logo'/>
      ) : (
        <img src={truckSmall} alt="" onLoad={handleImageLoad} className='logo'/>
      )}
    </>
  );
}

export default ImageLoader;